import React, { useEffect, useState } from 'react';
import {
  BoardListAPI,
  BoardPopularListAPI,
  BoardShareListAPI,
  BoardTagsRelatedAPI,
} from '../api/boardApi';
import Card from '../../components/Card';
import { CardType } from '../../_common/collectionTypes';
import { MainListTypeState } from '../../reducers/mainListTypeSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import EmptyState from '../../components/EmptyState';
import { useInView } from 'react-intersection-observer';
import CommunityBanner from './CommunityBanner';
import styled from 'styled-components';

const BoardList = () => {
  interface AllListParams {
    readonly id: IdType;
    readonly allDataLoaded: boolean;
  }
  type IdType = null | string;

  const [list, setList] = useState<CardType[]>([]);
  const [loading, setLoading] = useState<boolean>(false); // Move useState inside the component
  const TAKE: number = 5;
  const { buttonType }: MainListTypeState = useSelector(
    (state: RootState) => state.sideBarButton,
  );
  const [ref, inView] = useInView();
  const [lastInView, setLastInView] = useState<boolean>(false);
  const [id, setId] = useState<IdType>(null);
  const [allDataLoaded, setAllDataLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (list.length > 0 && inView && !lastInView) {
      ListApi({ id, allDataLoaded });
    }
    setLastInView(inView);
  }, [inView]);

  useEffect(() => {
    setAllDataLoaded(false);
    setId(null);
    setList([]);
    setLastInView(false);
    ListApi({ id: null, allDataLoaded: false });
  }, [buttonType]);

  const ListApi = async ({ id, allDataLoaded }: AllListParams) => {
    if (allDataLoaded) return;

    try {
      let response;
      switch (buttonType) {
        case 'HOME':
          response = await BoardListAPI({
            take: TAKE,
            lastId: id,
            category: null,
          });
          break;
        case 'POPULAR':
          response = await BoardPopularListAPI({
            take: TAKE,
            lastId: id,
            category: null,
          });
          break;

        case 'TAGMATCH':
          response = await BoardTagsRelatedAPI({
            take: TAKE,
            lastId: id,
            category: null,
            userId: localStorage.getItem('id') as string,
          });
          break;
        case 'FREQUENTSHARE':
          response = await BoardShareListAPI({
            take: TAKE,
            lastId: id,
            category: null,
          });
          break;
        default:
          response = await BoardListAPI({
            take: TAKE,
            lastId: id,
            category: buttonType,
          });
          break;
      }

      const res = response?.data?.response;
      if (!res) return;
      const newCards = res.current_list;
      setList((prevList) => [...prevList, ...newCards]);

      if (newCards.length > 0) {
        setId(newCards[newCards.length - 1].id);
      } else {
        setAllDataLoaded(true);
      }
    } catch (err) {
      console.error('API error: ', err);
    }
  };

  return (
    <>
      <MainContainer>
        {buttonType !== 'HOME' &&
          buttonType !== 'POPULAR' &&
          buttonType !== 'TAGMATCH' &&
          buttonType !== 'FREQUENTSHARE' && (
            <>
              <CommunityBanner />
            </>
          )}
        <CardsContainer>
          {list.length ? (
            list.map((el: CardType, index) => {
              return (
                <React.Fragment key={`${el.id}-${index}`}>
                  <Card
                    id={el.id}
                    category={el.category}
                    title={el.title}
                    nickname={el.nickname}
                    createdAt={el.created_at}
                    content={el.content}
                    type={el.type}
                    shareCount={el.share_count}
                  />
                </React.Fragment>
              );
            })
          ) : (
            <EmptyState />
          )}
        </CardsContainer>
      </MainContainer>
      <InvisibleRefContainer ref={ref}>d</InvisibleRefContainer>
    </>
  );
};

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  box-sizing: border-box;
  margin-left: 1%;
  margin-top: 1%;

  @media (max-width: 768px) {
    margin-left: 0;
    max-width: 600px;
  }
`;

const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 800px; /* Set an appropriate max width */
  box-sizing: border-box;
  padding: 0 20px; /* Add left and right padding */
`;

const InvisibleRefContainer = styled.div`
  opacity: 0;
`;

export default BoardList;
