import React from 'react';

const AdminList = () => {
  return (
    <>
      <div className="admin-list-container">
        <p>Admin List is under construction.</p>
      </div>
    </>
  );
};

export default AdminList;
